import React from 'react'
import Helmet from 'react-helmet'
import useSiteMetadata from '@helpers/useSiteMetadata'

const Seo = ({
  title,
  description,
  excerpt,
  meta,
  keywords,
  author,
  date,
  timeToRead,
  children,
  thumbnail,
  type = 'website',
  eventDetails,
  siteUrl
}) => {
  const site = useSiteMetadata()

  const social = (author && author.social) || site.social || []
  const twitter =
    social.find(s => s.name && s.name.toLowerCase() === 'twitter') || {}

  description = excerpt || description || site.description

  thumbnail = thumbnail && thumbnail.hero && thumbnail.hero.src
  const thumbnailUrl =
    thumbnail &&
    (thumbnail.startsWith('//')
      ? thumbnail
      : siteUrl && `${siteUrl}${thumbnail}`)

  // ✅ FIX: Ensure URL and Image URL are defined
  const url = siteUrl || site.url || 'https://acslive.org'
  const imageUrl =
    thumbnailUrl ||
    'https://cdn.sanity.io/images/10r6k1h7/production/87e2cb173d4c822b56adb7e48410065655d96181-1920x1082.webp'

  const metaTags = [
    { itemprop: 'name', content: title || site.title },
    { itemprop: 'description', content: description },
    { name: 'description', content: description },

    { property: 'og:title', content: title || site.title },
    { property: 'og:description', content: description },
    { property: 'og:type', content: 'website' },
    { property: 'og:site_name', content: site.name },
    { property: 'og:image', content: imageUrl },

    { name: 'twitter:card', content: 'summary' },
    { name: 'twitter:site', content: site.name },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:creator', content: twitter.url }
  ]

  if (keywords && keywords.length > 0) {
    metaTags.push({ name: 'keywords', content: keywords.join(', ') })
  }

  if (date) {
    metaTags.push({ name: 'article:published_time', content: date })
  }

  if (timeToRead) {
    metaTags.push({ name: 'twitter:label1', value: 'Okuma süresi' })
    metaTags.push({ name: 'twitter:data1', value: `${timeToRead} dak okunur` })
  }

  if (meta) {
    metaTags.push(meta)
  }

  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': type,
    name: title,
    description,
    url, // ✅ FIXED: url is now properly defined
    image: imageUrl // ✅ FIXED: imageUrl is now properly defined
  }

  if (type === 'BlogPosting') {
    schemaMarkup.author = {
      '@type': 'Person',
      name: author
    }
    schemaMarkup.datePublished = date
    schemaMarkup.publisher = {
      '@type': 'Organization',
      name: 'ACS',
      logo: {
        '@type': 'ImageObject',
        url:
          'https://acslive.org/static/427bf850481761652627d10b098046a8/37d5a/logo.png'
      }
    }
  }

  if (type === 'FAQPage' && eventDetails?.faqs) {
    schemaMarkup.mainEntity = eventDetails.faqs.map(faq => ({
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.answer
      }
    }))
  }

  if (type === 'Event' && eventDetails) {
    schemaMarkup.startDate = eventDetails.startDate
    schemaMarkup.endDate = eventDetails.endDate
    schemaMarkup.location = {
      '@type': 'Place',
      name: eventDetails.location
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
      title={title}
      titleTemplate={`%s | ${site.title}`}
      meta={metaTags}
    >
      <script type='application/ld+json'>{JSON.stringify(schemaMarkup)}</script>
      {children}
    </Helmet>
  )
}

export default Seo
